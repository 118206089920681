<template>
    <div v-show="visible" class="video-popup">
        <div class="mask" @click="hide()"></div>
        <div v-if="visible" class="content">
            <div class="close-icon" @click="hide()">x</div>
            <iframe width="100%" height="100%" :src="url" frameborder="0" allowtransparency="true" allowfullscreen="allowfullscreen"></iframe>
        </div>
    </div>
</template>

<script setup lang="ts">
const visible = ref(false);
const url = ref("");

const show = (current) => {
    visible.value = true;
    url.value = current;
    document.documentElement.style.overflow = "hidden";
};

const hide = () => {
    visible.value = false;
    url.value = "";
    document.documentElement.style.overflow = "unset";
};

defineExpose({
    show,
    hide,
});
</script>

<style setup lang="scss">
.video-popup {
}
</style>
